import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import PostsNavigation from '../../components/Post/posts-navigation';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import HeroPost from '../../components/Hero/hero-post';
import heroImg from '../../images/posts/001/gatsby-link.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeroPost gradientFrom="#FDFCFB" gradientTo="#E2D1C3" mdxType="HeroPost">
  <img src={heroImg} alt="Hero Gatsby with Link" height="251" />
    </HeroPost>
    <h1>{`Dynamic partiallyActive Link in Gatsby`}</h1>
    <Date date="September 29, 2020" dateMachine="2020-09-29" mdxType="Date" />
    <p>{`Gatsby offers you to use `}<inlineCode parentName="p">{`activeClassName`}</inlineCode>{` where you can adjust the
style of your link to indicate that a user is "under" that module or
section of your page. In other words, it helps people to understand
where they are.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{4}",
        "{4}": true
      }}>{`<Link
  to="/about-me"
  className={styles.menuLink}
  activeClassName={styles.menuLinkActive}
>
  About me
</Link>
`}</code></pre>
    <p>{`Styles defined for `}<inlineCode parentName="p">{`styles.menuLinkActive`}</inlineCode>{` in the example above are then applied and everything is cool.
Say, the link is red when a user accesses the`}<inlineCode parentName="p">{`/about-me`}</inlineCode>{` page.`}</p>
    <p>{`But what if you have a scenario like mine, where the "Blog" link should be active when people access `}<inlineCode parentName="p">{`/posts/:title`}</inlineCode>{` and
blog is my homepage as well (path `}<inlineCode parentName="p">{`/`}</inlineCode>{`)?
Well, the first thought is about `}<inlineCode parentName="p">{`partiallyActive`}</inlineCode>{` attribute and using it like that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{5}",
        "{5}": true
      }}>{`<Link
  to="/"
  className={styles.menuLink}
  activeClassName={styles.menuLinkActive}
  partiallyActive={true}
>
  Blog
</Link>
`}</code></pre>
    <p>{`But in that case, it will mean that the "Blog" link is active all the time. `}<inlineCode parentName="p">{`/`}</inlineCode>{` matches every other URL.
Fortunately, there is a way to make it more dynamic. Just use a function and fire it immediately.
Gatsby's `}<inlineCode parentName="p">{`<Link>`}</inlineCode>{` doesn't support passing a function so we still need to pass a `}<inlineCode parentName="p">{`boolean`}</inlineCode>{`. Hence, the
immediately called function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{5-10}",
        "{5-10}": true
      }}>{`<Link
  to="/"
  className={styles.menuLink}
  activeClassName={styles.menuLinkActive}
  partiallyActive={(() => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      return pathName === '/' || pathName.includes('/posts/');
    }
  })()}
>
  Blog
</Link>
`}</code></pre>
    <p>{`What happens here? We read `}<inlineCode parentName="p">{`pathname`}</inlineCode>{` from the current location and return `}<inlineCode parentName="p">{`true`}</inlineCode>{` when we deal with
homepage (`}<inlineCode parentName="p">{`/`}</inlineCode>{`) or the pathname includes `}<inlineCode parentName="p">{`/posts`}</inlineCode>{`.`}</p>
    <p>{`Please notice the `}<inlineCode parentName="p">{`if`}</inlineCode>{` statement where we check if `}<inlineCode parentName="p">{`window`}</inlineCode>{`
object is there. That's needed for building process in Gatsby that happens in non-browser environment.
The page is server-rendered so it doesn't have a context of browser when building.`}<br />{`
But that's ok. Once, in the browser we will get the context and improve the experience.`}</p>
    <p>{`It works on my blog, so it should work in your case as well.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox mdxType="TwitterBox" />
    </div>
    <PostsNavigation nextTitle="Content Curation is going to be a job and how Content Marketing killed the web" nextLink="/posts/content-curation-and-marketing" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      